import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';


const homeUrl = process.env.PUBLIC_URL;

function Home() {
    return (
        <Container>
            <Row>
                <Col>
                    <Alert key='dark' variant='dark'>
                        自分のものを貸したり、友達のものを貸したりしよう
                    </Alert>
                </Col>
            </Row>
            <h3>レンタル可能品一覧</h3>
            
            
            
        </Container >
    );
};

export default Home;