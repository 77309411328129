import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ReactDOM from "react-dom"
import Home from './component/Home';
import './App.css';

const homeUrl = process.env.PUBLIC_URL;


function App() {
  return (
    <div className="App">
      <p>ryuuseisouken</p>


      <Routes>
        <Route path={homeUrl} element={<Home />} />
      </Routes>

    </div>
  );
}

export default App;
